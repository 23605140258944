import React from "react";
import { Col, Label, Input, FormFeedback } from "reactstrap";
const StateField = (data) => {
    const { props } = data;
    return (
        <>
            <Label htmlFor='state' sm={1}>
                State
            </Label>
            <Col sm={3}>
                <Input
                    id='state'
                    name='state'
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.state || ""}
                    invalid={props.touched.state && props.errors.state ? true : null}
                />
                <FormFeedback>{props.errors.state}</FormFeedback>
            </Col>
        </>
    );
};
export default StateField;
