import React from "react";
import { Col, Label, Input, FormFeedback } from "reactstrap";
const CityField = (data) => {
    const { props } = data;
    return (
        <>
            <Label htmlFor='city' sm={2}>
                City
            </Label>
            <Col sm={3}>
                <Input
                    id='city'
                    name='city'
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.city || ""}
                    invalid={props.touched.city && props.errors.city ? true : null}
                />
                <FormFeedback>{props.errors.city}</FormFeedback>
            </Col>
        </>
    );
};
export default CityField;
