import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { Alert, Col, Row, UncontrolledTooltip } from "reactstrap";
import { NavLink as RRNavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
const CurrentUser = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const [loading, setLoading] = useState(true);
    const [currUser, setCurrUser] = useState(false);
    useEffect(() => {
        if (authState.accessToken) {
            oktaAuth
                .getUser()
                .then((oktaUser) => {
                    const userguid = oktaUser.sub;
                    const token = oktaAuth.getAccessToken();
                    axios
                        .get(process.env.REACT_APP_APIHOST + "/api/me", {
                            headers: {
                                authorization: `Bearer ${token}`,
                                userguid,
                            },
                        })
                        .then((res) => {
                            console.log(res.data.me);
                            setCurrUser(res.data.me);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.errorCode === "login_required") {
                        window.location.reload(true);
                    }
                });
        } else {
            setCurrUser("nouser");
        }
    }, [authState]);
    useEffect(() => {
        if (currUser) {
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        }
    }, [currUser]);
    return loading ? (
        <Row>
            <Col sm={12}>
                <Alert color='info' className='text-center'>
                    <div>
                        <strong>
                            <FontAwesomeIcon icon={faSpinner} spin />
                            Loading User Data...
                        </strong>
                    </div>
                    <div>If this loading message appears for a long time, it means you need to log in first, or that you do not have an account.</div>
                </Alert>
            </Col>
        </Row>
    ) : currUser !== "nouser" ? (
        <>
            <Row>
                <Col sm={6}>
                    <h1 className='mt-3'>
                        Welcome <strong>{currUser.profile.firstName}, </strong>
                    </h1>
                    <div className='my-3'>
                        <p>
                            Thank you for validating your access to GuestFocus! ​ Starting March 29th, when you sign into the Matrix and click on the GuestFocus widget you will see your personal
                            GuestFocus dashboard.
                        </p>{" "}
                        ​ <p>Check back at that time to unlock unique access into the ​ minds of our guests!</p>
                    </div>
                    <div className='my-3'>Thank you,​ The GuestFocus Team</div>
                    <hr />
                </Col>
                <Col sm={6}>
                    <div className='p-3'>
                        <img src='/images/GuestFocus_Logo1.png' alt='GuestFocus Logo' />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <h3>Your Credentials</h3>
                    <Row>
                        <Col sm={6}>
                            <div>
                                Your username{" "}
                                <sup>
                                    <FontAwesomeIcon id='usernameTooltip' icon={faQuestion} style={{ cursor: "pointer" }} />
                                </sup>
                                is:
                                <UncontrolledTooltip placement='right' target='usernameTooltip'>
                                    Remember your username is different than your email address. You cannot use a Matrix username as an email address.
                                </UncontrolledTooltip>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <strong className='text-lowercase'>{currUser.profile.login}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div>Your contact email(s):</div>
                        </Col>
                        <Col sm={6}>
                            <strong className='text-lowercase'>{currUser.profile.email}</strong>
                            {currUser.profile.secondEmail ? (
                                <span>
                                    {" "}
                                    &amp;<strong className='text-lowercase'> {currUser.profile.secondEmail}</strong>
                                </span>
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div>Your account was created on</div>
                        </Col>
                        <Col sm={6}>
                            <strong>{moment(currUser.created).format("dddd, MMMM Do YYYY")}</strong>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <div>Your account was activated on</div>
                        </Col>
                        <Col sm={6}>
                            <strong>{moment(currUser.activated).format("dddd, MMMM Do YYYY")}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div>Your last login was on</div>
                        </Col>
                        <Col sm={6}>
                            <strong>{moment(currUser.lastLogin).format("dddd, MMMM Do YYYY")}</strong>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    ) : (
        <Row>
            <Col sm={12}>
                <Alert color='warning' className='text-center'>
                    <div>
                        <strong>No User Present</strong>
                    </div>
                    <div>
                        You need to either log in:{" "}
                        <Link to={`/`} tag={RRNavLink}>
                            Login
                        </Link>
                    </div>
                    <div>
                        Or you need to either request access:{" "}
                        <Link to={`/requestaccess`} tag={RRNavLink}>
                            Request Access
                        </Link>
                    </div>
                </Alert>
            </Col>
        </Row>
    );
};

export default CurrentUser;
