import React from "react";
import { Col, Label, Input, FormFeedback } from "reactstrap";
const ZipCodeField = (data) => {
    const { props } = data;
    return (
        <>
            <Label htmlFor='zipCode' sm={1}>
                ZIP
            </Label>
            <Col sm={2}>
                <Input
                    id='zipCode'
                    name='zipCode'
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.zipCode || ""}
                    invalid={props.touched.zipCode && props.errors.zipCode ? true : null}
                />
                <FormFeedback>{props.errors.zipCode}</FormFeedback>
            </Col>
        </>
    );
};
export default ZipCodeField;
