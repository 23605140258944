import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-duotone-svg-icons";

const Help = () => {
    const [admins, setAdmins] = useState([]);
    const [adminDisplay, setAdminDisplay] = useState([]);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const getMatrixAdmins = async () => {
        axios
            .get(`${process.env.REACT_APP_APIHOST}/api/getmatrixadmins`, {
                headers: {
                    authorization: `Bearer ${process.env.REACT_APP_API_AUTH_PASSPHRASE}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    let displayadmins = _.compact(
                        _.map(res.data.admins, (admin, i) => {
                            if (admin.webdisplay) {
                                return admin;
                            }
                        })
                    );
                    setAdmins(displayadmins);
                } else {
                }
            })
            .catch(function (error) {});
    };

    useEffect(() => {
        if (admins.length >= 1) {
            let adminslist = _.groupBy(admins, "brandname");
            adminslist = _.map(adminslist, (item, i) => {
                return (
                    <li key={i}>
                        <strong>{i}</strong>
                        <ul className='list-unstyled pl-3'>
                            {_.map(item, (admin, i) => {
                                return (
                                    <li key={i}>
                                        <a href={`mailto:${admin.email}`}>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            &nbsp;
                                            {admin.name}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            });
            setAdminDisplay(adminslist);
        } else {
            getMatrixAdmins();
        }
    }, [admins]);
    return (
        <>
            <div className='bg-light p-3'>
                <Row>
                    <Col sm={12}>
                        <h1>
                            <img src='/images/FOCUSBubble_square80.png' alt='Focus' />
                            Brands Matrix Help
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm={9}>
                        <h2>What is Okta?</h2>
                        <p>Focus Brands and Okta have teamed up to provide a robust Single Sign On solution for the Focus Brands Matrix. </p>
                        <p>
                            Once you sign in, your Okta home page displays all your applications in one location. Simply, click the application's corresponding icon and each application opens in a new
                            browser window or tab and you are automatically logged-in.
                        </p>
                        <hr />
                        <h2>My Focus Brands Matrix Account</h2>
                        <p>
                            When we launched the updated Focus Brands Matrix, we went through and cleaned up a lot of old accounts. We started from scratch in a lot of ways to make sure we had the
                            most up to date information about the accounts logging in.
                        </p>
                        <p>This also allowed us to collect more information which will allow us to create customized content in the web apps we provide to you.</p>
                        <div className='alert alert-info'>
                            <small>
                                <strong>Note: </strong>It is very important that you complete all the fields for the activation process. Your contact email and recovery questions need to be set in
                                order to use the password reset options.
                            </small>
                        </div>
                        {/* <h3>If you had an old account but it no longer works</h3>
                        <p>
                            Try <Link to={"/requestaccess"}>requesting access</Link> again.
                        </p> */}
                        <h3>I missed the activation email / waited to long to respond</h3>
                        <p>Reach out to your respective brands Matrix Admin and request that they resend your activation email.</p>
                        <h3>I need to get my employees access</h3>
                        <p>
                            You can either send them to the <Link to={"/requestaccess"}>Request Access</Link> form, or reach out to your Matrix Admin.
                        </p>
                        <div className='alert alert-warning'>
                            <small>
                                <strong>Note: </strong>No shared/generic accounts.
                            </small>
                        </div>
                        <h3>Is Locked Out</h3>
                        <p>If you attempt to login 10 times with the wrong credentials, your account will be locked out. Your account is set to automatically unlock after 20 minutes.</p>
                        <hr />
                        <h2>My Password</h2>
                        <h3>Has expired</h3>
                        <p>
                            You should be able to reset your own password. If for some reason you've forgotten your recovery question and answer, reach out to your Matrix admin and let them know you
                            need your Recover password questions reset.
                        </p>
                        <div className='text-center'>
                            <Button color='info' size='sm' onClick={toggle}>
                                Password Recovery Question Info
                            </Button>
                        </div>
                        <h3>Doesn't work</h3>
                        <p>Verify that you're using the proper username. Double check the spelling and make sure you put in @focusbrandsmatrix.com.</p>
                        <h3>I don't remember my username / password</h3>
                        <p>Reach out to the Matrix Admin for your respective brand. </p>
                    </Col>
                    <Col sm={3}>
                        <div className='bg-white p-2'>
                            <h3>Matrix Admins</h3>
                            <ul className='list-unstyled pl-3'>{adminDisplay}</ul>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={modal} toggle={toggle} size='lg'>
                <ModalHeader toggle={toggle}>Forgotten Password Recovery Questions</ModalHeader>
                <ModalBody>
                    <p>
                        You'll need to login to your account successfully first to be able to update your recovery questions. Once you're logged in click on your name in the upper right corner and
                        then click on settings.
                    </p>
                    <div className='w-100'>
                        <img src='/images/okta-recovery-questions/okta-recovery-questions.png' alt='Screenshot of Okta Settings Option' style={{ width: "100%" }} />
                    </div>
                    <hr />
                    <p>Next, on your Account page, you'll need to find the Forgotten Password Question section. Then click on edit.</p>
                    <div className='w-100'>
                        <img src='/images/okta-recovery-questions/okta-recovery-questions1.png' alt='Screenshot of Okta Account Edit location' style={{ width: "100%" }} />
                    </div>
                    <hr />
                    <p>Finally you'll be able to choose your own Forgotten Password Question and Answer.</p>
                    <div className='text-center'>
                        <img src='/images/okta-recovery-questions/okta-recovery-questions2.png' alt='Screenshot of Okta Password Recovery Question dialog box' style={{ width: "65%" }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={toggle}>
                        Got It!
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Help;
