import React from "react";
import { Col, Label, Input, FormFeedback } from "reactstrap";
const LastNameField = (data) => {
    const { props, label, required } = data;
    return (
        <>
            <Label htmlFor='lastName' sm={2}>
                {label ? label : "Last Name"}
                {required ? <small className='text-danger'>*</small> : ""}
            </Label>
            <Col sm={4}>
                <Input
                    id='lastName'
                    name='lastName'
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.lastName || ""}
                    invalid={props.touched.lastName && props.errors.lastName ? true : null}
                />
                <FormFeedback>{props.errors.lastName}</FormFeedback>
            </Col>
        </>
    );
};
export default LastNameField;
