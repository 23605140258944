import React, { createRef } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";

import OktaSigninWidget from "../components/OktaSigninWidget";

const Main = () => {
    const imgID = Math.floor(moment().second() / 10); //limits to only 6 images

    return (
        <Row>
            <Col sm={12}>
                <div
                    style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundAttachment: "fixed",
                        backgroundImage: `url('/images/bg/image${imgID}.jpg')`,
                    }}>
                    <OktaSigninWidget />
                </div>
            </Col>
        </Row>
    );
};

export default Main;
