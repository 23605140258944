import React, { useState, useEffect } from "react";
import { Col, Row, Label, CustomInput, UncontrolledTooltip, Alert } from "reactstrap";
import _ from "lodash";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionSquare } from "@fortawesome/pro-duotone-svg-icons";
import BrandCircles from "../Common/BrandCircles";

const BrandField = (data) => {
    const { props } = data;
    const [intlBrands, setIntlBrands] = useState([]);
    const [domBrands, setDomBrands] = useState([]);
    const [brands, setBrands] = useState([]);
    const getBrands = async () => {
        axios
            .get(`${process.env.REACT_APP_APIHOST}/api/getbrands`, {
                headers: {
                    authorization: `Bearer ${process.env.REACT_APP_API_AUTH_PASSPHRASE}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setBrands(res.data.brands);
                } else {
                }
            })
            .catch(function (error) {});
    };

    useEffect(() => {
        if (brands.length >= 1) {
            setDomBrands(
                _.map(brands, (brand, i) => {
                    if (brand.location === "domestic" && brand.active) {
                        return (
                            <CustomInput
                                key={i}
                                id={`${brand.slug}-selectcheckbox`}
                                label={
                                    <>
                                        <BrandCircles location='domestic' brandslugs={[brand.slug]} /> {brand.name}
                                    </>
                                }
                                type='checkbox'
                                name='assocBrands'
                                checked={_.indexOf(props.values.assocBrands, brand.slug) !== -1 && props.values.location === "domestic"}
                                value={brand.slug}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        );
                    }
                })
            );
            setIntlBrands(
                _.map(brands, (brand, i) => {
                    if (brand.location === "international" && brand.active) {
                        return (
                            <CustomInput
                                key={i}
                                id={`${brand.slug}-selectcheckbox`}
                                label={
                                    <>
                                        <BrandCircles location='international' brandslugs={[brand.slug]} /> {brand.name}
                                    </>
                                }
                                type='checkbox'
                                name='assocBrands'
                                checked={_.indexOf(props.values.assocBrands, brand.slug.replace("intl-", "")) !== -1 && props.values.location === "international"}
                                value={brand.slug.replace("intl-", "")}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        );
                    }
                })
            );
        } else {
            getBrands();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brands, props.values.location, props.values.assocBrands]);
    useEffect(() => {
        // Handles removing brands specific to International or Domestic when Location is changed on the form
        let intlBrands = _.map(brands, (brand, i) => {
            if (brand.location === "international" && brand.active) {
                return brand.slug.replace("intl-", "");
            }
        });
        intlBrands = _.compact(intlBrands);
        let domBrands = _.map(brands, (brand, i) => {
            if (brand.location === "domestic" && brand.active) {
                return brand.slug;
            }
        });
        domBrands = _.compact(domBrands);
        if (props.values.location === "international") {
            // console.log(_.difference(domBrands, intlBrands));
            _.pullAllWith(props.values.assocBrands, _.difference(domBrands, intlBrands), _.isEqual);
        } else if (props.values.location === "domestic") {
            // console.log(_.difference(intlBrands, domBrands));
            _.pullAllWith(props.values.assocBrands, _.difference(intlBrands, domBrands), _.isEqual);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values.location]);

    return (
        <>
            <Label htmlFor='brands' sm={2}>
                Brand<small className='text-danger'>*</small>
                {props.values.location ? (
                    ""
                ) : (
                    <>
                        <span style={{ cursor: "pointer" }} id='brandHelpIcon'>
                            &nbsp;
                            <FontAwesomeIcon icon={faQuestionSquare} />
                        </span>
                        <UncontrolledTooltip placement='right' target='brandHelpIcon'>
                            You must first select the location before you can select the brand.
                        </UncontrolledTooltip>
                    </>
                )}
            </Label>
            <Col sm={10}>
                <div className={props.errors.userRole && props.touched.userRole ? "rounded border border-danger no-gutters p-2" : "rounded no-gutters p-2"}>
                    {!props.values.location ? (
                        <Alert color='warning'>Please select a location.</Alert>
                    ) : (
                        <div style={{ columns: 2 }}>{props.values.location === "domestic" ? domBrands : props.values.location === "international" ? intlBrands : ""}</div>
                    )}
                </div>
                <div className={props.errors.location && props.touched.location ? "text-danger" : "d-none"}>
                    <small>{props.errors.location}</small>
                </div>
            </Col>
        </>
    );
};
export default BrandField;
