import React from "react";
import { Col, Label, Input, FormFeedback } from "reactstrap";
const PrimaryPhoneField = (data) => {
    const { props } = data;
    return (
        <>
            <Label htmlFor='primaryPhone' sm={2}>
                Phone
            </Label>
            <Col sm={4}>
                <Input
                    id='primaryPhone'
                    name='primaryPhone'
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.primaryPhone || ""}
                    invalid={props.touched.primaryPhone && props.errors.primaryPhone ? true : null}
                />
                <FormFeedback>{props.errors.primaryPhone}</FormFeedback>
            </Col>
        </>
    );
};
export default PrimaryPhoneField;
