import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faCircle, faGlobe, faExclamationTriangle, faUsers } from "@fortawesome/pro-duotone-svg-icons";
import _ from "lodash";

const BrandCircles = (data) => {
    const { brandslugs, brandname, location } = data;
    const [brands, setBrands] = useState([]);
    const getBrands = async () => {
        axios
            .get(`${process.env.REACT_APP_APIHOST}/api/getbrands`, {
                headers: {
                    authorization: `Bearer ${process.env.REACT_APP_API_AUTH_PASSPHRASE}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setBrands(res.data.brands);
                } else {
                }
            })
            .catch(function (error) {});
    };

    useEffect(() => {
        if (brands.length >= 1) {
        } else {
            getBrands();
        }
    }, [brands]);
    let brandsDiv;
    if (!brandname && brandslugs && Array.isArray(brandslugs)) {
        // brandslugs must be an array;
        brandsDiv = _.map(brandslugs, (slug, i) => {
            if (location === "all") {
                const tagBrand = _.find(brands, { slug, location: "domestic" });
                if (tagBrand) {
                    return (
                        <span key={i} title={`${tagBrand.name} Domestic and Intl`} className={`text-${tagBrand.slug}`}>
                            <FontAwesomeIcon icon={faUsers} size='lg' />
                        </span>
                    );
                } else if (slug === "zall") {
                    return (
                        <span key={i} title='All Brands' className={`text-fb`}>
                            <FontAwesomeIcon icon={faUsers} size='lg' />
                        </span>
                    );
                } else {
                    return null;
                }
            } else {
                const tagBrand = _.find(brands, { slug, location });
                if (tagBrand) {
                    if (location === "international") {
                        return (
                            <span key={i} title={tagBrand.name} className={`text-intl-${tagBrand.slug}`}>
                                <FontAwesomeIcon icon={faDotCircle} size='lg' />
                            </span>
                        );
                    } else if (location === "domestic") {
                        return (
                            <span key={i} title={tagBrand.name} className={`text-${tagBrand.slug}`}>
                                <FontAwesomeIcon icon={faCircle} size='lg' />
                            </span>
                        );
                    }
                } else {
                    return (
                        <span key={i} className='text-danger'>
                            <FontAwesomeIcon icon={faExclamationTriangle} size='lg' title='Not vaild due to location not being set.' />
                        </span>
                    );
                }
            }
        });
        return brandsDiv;
    } else if (!brandname && brandslugs && !Array.isArray(brandslugs)) {
        console.log("brands are not in an array");
        return null;
    } else if (brandname && !brandslugs) {
        //If single proper FRM brand name is sent
        const frm_name = brandname;
        const tagBrand = _.find(brands, { frm_name });
        if (tagBrand.location === "international") {
            brandsDiv = (
                <span title={tagBrand.name} className={`text-intl-${tagBrand.slug}`}>
                    <FontAwesomeIcon icon={faDotCircle} size='lg' />
                </span>
            );
        } else {
            brandsDiv = (
                <span title={tagBrand.name} className={`text-${tagBrand.slug}`}>
                    <FontAwesomeIcon icon={faCircle} size='lg' />
                </span>
            );
        }

        return brandsDiv;
    } else {
        return null;
    }
};
export default BrandCircles;
