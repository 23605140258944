import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Row, Col, Button, Alert, UncontrolledAlert } from "reactstrap";
import * as Yup from "yup";
import slugify from "slugify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight, faToggleOff, faToggleOn } from "@fortawesome/pro-duotone-svg-icons";

import FirstNameField from "../components/Fields/FirstNameField";
import LastNameField from "../components/Fields/LastNameField";
import EmailField from "../components/Fields/EmailField";
import SecondEmailField from "../components/Fields/SecondEmailField";
import PrimaryPhoneField from "../components/Fields/PrimaryPhoneField";
import MobilePhoneField from "../components/Fields/MobilePhoneField";
import StreetAddressField from "../components/Fields/StreetAddressField";
import CityField from "../components/Fields/CityField";
import StateField from "../components/Fields/StateField";
import ZipCodeField from "../components/Fields/ZipCodeField";
import CountryCodeField from "../components/Fields/CountryCodeField";
import UserRoleField from "../components/Fields/UserRoleField";
import LocationField from "../components/Fields/LocationField";
import BrandField from "../components/Fields/BrandField";
import RequestedStoresField from "../components/Fields/RequestedStoresField";

const RequestAccess = () => {
    let history = useHistory();
    const [admins, setAdmins] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState("showform");
    const [showFormHelp, setShowFormHelp] = useState(false);
    const [existingAcctsMsg, setExistingAcctsMsg] = useState(false);
    const [initFormValues, setInitFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        checkedemail: false,
        secondEmail: "",
        userRole: "",
        location: "",
        assocBrands: "",
        request_date: null,
        status: null,
        approval_date: null,
        approvalData: null,
        requestedStores: "",
        comments: "",
        submission_slug: null,
        primaryPhone: "",
        mobilePhone: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        countryCode: "",
    });
    const [apiRespMessage, setApiRespMessage] = useState(null);
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const SignupSchema = Yup.object().shape({
        firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
        lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        secondEmail: Yup.string().email("Invalid email"),
        primaryPhone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
        mobilePhone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
        userRole: Yup.string().required("Required"),
        location: Yup.string().required("Required"),
        assocBrands: Yup.array().required("Required"),
        countryCode: Yup.string().required("Choosing a Country is required"),
    });
    const toggleHelp = () => setShowFormHelp(!showFormHelp);
    const getNotifyAdmins = async (brands, location) => {
        let brandArry;
        if (location === "domestic") {
            brandArry = _.compact(brands.split(";"));
        } else if (location === "international") {
            brandArry = ["intl"];
        }

        return axios
            .get(`${process.env.REACT_APP_APIHOST}/api/getmatrixadmins`, {
                headers: {
                    authorization: `Bearer ${process.env.REACT_APP_API_AUTH_PASSPHRASE}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    let notifyadmins = _.compact(
                        _.map(res.data.admins, (admin, i) => {
                            if (admin.requestnotify && _.includes(brandArry, admin.brandslug)) {
                                return admin;
                            }
                        })
                    );
                    return notifyadmins;
                }
            })
            .catch(function (error) {});
    };
    const capitalizeFirstLetter = ([first, ...rest]) => (first ? first.toUpperCase() + rest.join("") : "");
    const checkRequest = async (submission_slug) => {
        return axios
            .post(
                `${process.env.REACT_APP_APIHOST}/api/checkrequest`,
                { submission_slug },
                {
                    headers: {
                        authorization: `Bearer ${process.env.REACT_APP_API_AUTH_PASSPHRASE}`,
                    },
                }
            )
            .then((res) => {
                if (res.data.success) {
                    document.getElementById("apiresponse").scrollIntoView();
                    setApiRespMessage(
                        <UncontrolledAlert color='danger' className='mb-1'>
                            {res.data.message}
                        </UncontrolledAlert>
                    );
                    return false;
                } else {
                    return true;
                }
            })
            .catch(function (error) {});
    };
    const checkExistingContactEmails = async (email) => {
        return axios
            .get(`${process.env.REACT_APP_APIHOST}/api/checkexistingcontactemails`, {
                headers: {
                    authorization: `Bearer ${process.env.REACT_APP_API_AUTH_PASSPHRASE}`,
                    email,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    let _existingacctsmsg = (
                        <>
                            <div>
                                <strong>Warning!:</strong>
                            </div>
                            <div>The following accounts were found with the same contact email address:</div>

                            <div>
                                {_.map(res.data.users, (user, i) => {
                                    return (
                                        <Row key={i}>
                                            <Col sm={4}>
                                                <strong>Name: </strong>
                                                {user.profile.firstName} {user.profile.lastName}
                                            </Col>
                                            <Col sm={4}>
                                                <strong>Username: </strong>
                                                {user.profile.login}
                                            </Col>
                                            <Col sm={4}></Col>
                                        </Row>
                                    );
                                })}
                            </div>
                        </>
                    );
                    setExistingAcctsMsg(_existingacctsmsg);
                } else {
                    setExistingAcctsMsg(false);
                }
            })
            .catch(function (error) {});
    };
    const createRequest = async (dataObj) => {
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/createrequest`, dataObj, {
                headers: {
                    authorization: `Bearer ${process.env.REACT_APP_API_AUTH_PASSPHRASE}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setApiRespMessage(
                        <UncontrolledAlert color='success' className='mb-1'>
                            {res.data.message}
                        </UncontrolledAlert>
                    );
                } else {
                    setApiRespMessage(
                        <UncontrolledAlert color='danger' className='mb-1'>
                            {res.data.message}
                        </UncontrolledAlert>
                    );
                }
            })
            .catch(function (error) {});
    };
    const handleSubmit = async (values) => {
        setFormSubmitted("formsubmitted");
        values.request_date = moment().format("YYYY-MM-DD hh:mm:ss");
        const subSlug = values.firstName + " " + values.lastName + " " + values.email;
        values.submission_slug = slugify(subSlug, { replacement: "-", lower: true });
        values.firstName = capitalizeFirstLetter(values.firstName);
        values.lastName = capitalizeFirstLetter(values.lastName);
        values.email = values.email.toLowerCase();
        values.secondEmail = values.secondEmail.toLowerCase();
        values.assocBrands = _.join(values.assocBrands, ";");
        values.status = "Pending";
        console.log(values);
        checkRequest(values.submission_slug)
            .then((res) => {
                if (!res) {
                    throw new Error("Invalid Submission");
                } else {
                    getNotifyAdmins(values.assocBrands, values.location).then((admins) => {
                        // console.log(admins);
                        values.sendtoadmins = admins;
                        let _submitvalues = _.omit(values, ["checkedemail"]);
                        createRequest(_submitvalues);
                    });
                }
            })

            .catch((err) => console.log(err));
    };

    return (
        <>
            <div className='bg-light p-3'>
                <Row>
                    <Col sm={12}>
                        <h1>Request Access</h1>
                        <div id='apiresponse' className='mb-2'>
                            {apiRespMessage}
                        </div>
                        {formSubmitted === "showform" ? (
                            <Formik initialValues={initFormValues} onSubmit={handleSubmit} validationSchema={SignupSchema} enableReinitialize>
                                {(props) => {
                                    console.log(props);
                                    return (
                                        <Form>
                                            <Row className='mb-2'>
                                                <Col sm={6}>
                                                    <small className='text-danger'>*</small>Required
                                                </Col>
                                                <Col sm={6}>
                                                    <div className='text-right'>
                                                        {showFormHelp ? (
                                                            <Button color='info' size='sm' onClick={toggleHelp}>
                                                                <FontAwesomeIcon icon={faToggleOn} /> Help
                                                            </Button>
                                                        ) : (
                                                            <Button color='secondary' size='sm' onClick={toggleHelp}>
                                                                <FontAwesomeIcon icon={faToggleOff} /> Help
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='modFormRow pb-1' id='name-Row'>
                                                <FirstNameField props={props} required={true} />
                                                <LastNameField props={props} required={true} />
                                            </Row>
                                            <Row className='modFormRow pb-1' id='email-Row'>
                                                <EmailField
                                                    props={props}
                                                    required={true}
                                                    showFormHelp={showFormHelp}
                                                    checkExistingContactEmails={checkExistingContactEmails}
                                                    existingAcctsMsg={existingAcctsMsg}
                                                    helpMsg='Please use an email address that you can easily receive messages at.  This will be used for brand communications, as well as system messages.'
                                                />
                                            </Row>
                                            <Row className='modFormRow pb-1' id='userRole-Row'>
                                                <UserRoleField
                                                    props={props}
                                                    required={true}
                                                    label='Account Type'
                                                    showFormHelp={showFormHelp}
                                                    helpMsg='Please hover over the question marks to see the description of the account types.  Please keep in mind that this may setting may differ from ones related to specific applications.'
                                                />
                                            </Row>
                                            <Row className='modFormRow pb-1' id='location-Row'>
                                                <LocationField
                                                    props={props}
                                                    required={true}
                                                    showFormHelp={showFormHelp}
                                                    helpMsg='Please select the primary location for this account.  Make sure you note in the requested field below the specific stores you need to have access to.'
                                                />
                                            </Row>
                                            <Row className='modFormRow pb-1' id='brand-Row'>
                                                <BrandField props={props} required={true} showFormHelp={showFormHelp} />
                                            </Row>
                                            <Row className='modFormRow pb-1' id='requestedstores-Row'>
                                                <RequestedStoresField props={props} showFormHelp={showFormHelp} />
                                            </Row>
                                            <hr />
                                            {props.values.userRole === "franchise" || props.values.userRole === "support" ? (
                                                <Alert color='info'>
                                                    <div className='pb-1' id='optional-Row'>
                                                        <div>
                                                            <strong>Optional</strong>
                                                        </div>
                                                        <div>
                                                            <small>This information is optional at this time, but will be extremely useful as we provide more applications and content.</small>
                                                        </div>
                                                    </div>
                                                    <Row className='modFormRow pb-1' id='secondemail-Row'>
                                                        <SecondEmailField props={props} />
                                                    </Row>
                                                    <Row className='modFormRow pb-1' id='phone-Row'>
                                                        <PrimaryPhoneField props={props} />
                                                        <MobilePhoneField props={props} />
                                                    </Row>
                                                    <Row className='modFormRow pb-1' id='street-Row'>
                                                        <StreetAddressField props={props} />
                                                    </Row>
                                                    <Row className='modFormRow pb-1' id='citystatezip-Row'>
                                                        <CityField props={props} />
                                                        <StateField props={props} />
                                                        <ZipCodeField props={props} />
                                                    </Row>
                                                </Alert>
                                            ) : (
                                                ""
                                            )}
                                            <Row className='modFormRow pb-1' id='buttons-Row'>
                                                <Col sm={4} className='text=center'>
                                                    {(!existingAcctsMsg && !props.values.checkedemail) || (existingAcctsMsg && props.values.checkedemail) ? (
                                                        <Button color='primary' type='submit' disabled={props.isSubmitting || !props.isValid || !props.dirty || props.submitCount !== 0}>
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                                <Col sm={8}>
                                                    {!props.isValid ? (
                                                        <>
                                                            <div>
                                                                <small>Please correct the form errors before submitting.</small>
                                                            </div>
                                                            <div>
                                                                <strong>Required Fields: </strong>
                                                                {JSON.stringify(props.errors, null, 4)}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        ) : formSubmitted === "formsubmitted" ? (
                            <>
                                <div>
                                    <h2>Thank you!</h2>
                                    <p>You will recieve a confirmation email shortly.</p>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default RequestAccess;
