import React, { useState, useEffect } from "react";
import { Col, Row, Label, Input, CustomInput, FormGroup, FormFeedback, FormText, Alert } from "reactstrap";
import CountryCodeField from "./CountryCodeField";
const LocationField = (data) => {
    const { props, label, required, showFormHelp, helpMsg } = data;
    // console.log("test", props);
    return (
        <>
            <Label htmlFor='location' sm={2}>
                {label ? label : "Location"}
                {required ? <small className='text-danger'>*</small> : ""}
            </Label>
            <Col sm={10}>
                <div className={props.errors.location && props.touched.location ? "rounded border border-danger no-gutters p-2" : "rounded no-gutters p-2"}>
                    <div style={{ columns: 2 }}>
                        <CustomInput
                            id={`dom-radio`}
                            label={<>Domestic</>}
                            type='radio'
                            name='location'
                            checked={props.values.location === "domestic" || false}
                            value='domestic'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                        <CustomInput
                            id={`intl-radio`}
                            label={<>International</>}
                            type='radio'
                            name='location'
                            checked={props.values.location === "international" || false}
                            value='international'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </div>
                </div>
                <div className={props.errors.location && props.touched.location ? "text-danger" : "d-none"}>
                    <small>{props.errors.location}</small>
                </div>
                {showFormHelp ? (
                    <FormText>
                        <Alert color='info' className='p-1 mb-0'>
                            {helpMsg}
                        </Alert>
                    </FormText>
                ) : (
                    ""
                )}
            </Col>
            {props.values.location === "international" ? (
                <CountryCodeField props={props} required={true} />
            ) : props.values.location === "domestic" ? (
                <>
                    <Col sm={2}></Col>
                    <Col sm={4}>
                        <strong>Select a Country</strong>
                        <div style={{ columns: 2 }}>
                            <CustomInput
                                id={`domloca-radio`}
                                label={<>USA</>}
                                type='radio'
                                name='countryCode'
                                checked={props.values.countryCode === "US" || false}
                                value='US'
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                            <CustomInput
                                id={`domlocb-radio`}
                                label={<>Canada</>}
                                type='radio'
                                name='countryCode'
                                checked={props.values.countryCode === "CA" || false}
                                value='CA'
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        </div>
                        <FormFeedback>{props.errors.countryCode}</FormFeedback>
                    </Col>
                </>
            ) : (
                ""
            )}
        </>
    );
};
export default LocationField;
