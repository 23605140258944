import React from "react";
import Media from "react-media";
import { OverlayScrollbarsReactComponent } from "./OverlayScrollbars";
import { Container, Row } from "reactstrap";

import "../styles/style.css";
import "overlayscrollbars/css/OverlayScrollbars.css";
import TopNavigation from "./TopNavigation";

const Layout = (props) => {
    document.title = process.env.REACT_APP_SITETITLE;
    const osRef = React.createRef();
    return (
        <>
            <Container fluid>
                <Row>
                    <TopNavigation />
                </Row>
                <Row>
                    <div style={{ display: "block", width: "100%" }}>
                        <OverlayScrollbarsReactComponent
                            ref={osRef}
                            style={{
                                height: "100vh",
                            }}
                            options={{
                                className: "os-theme-dark",
                                sizeAutoCapable: true,
                                paddingAbsolute: true,
                                scrollbars: {
                                    clickScrolling: true,
                                    autoHide: "move",
                                },
                            }}>
                            <main id='content-main' style={{ marginTop: "85px" }} role='main'>
                                <Media query='(max-width: 600px)' render={() => <Container fluid>{props.children}</Container>} />
                                <Media query='(min-width: 600px)' render={() => <Container>{props.children}</Container>} />
                            </main>
                        </OverlayScrollbarsReactComponent>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Layout;
