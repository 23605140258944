import React from "react";
import { Col, Label, Input, FormFeedback } from "reactstrap";
const StreetAddressField = (data) => {
    const { props } = data;
    return (
        <>
            <Label htmlFor='streetAddress' sm={2}>
                Street
            </Label>
            <Col sm={10}>
                <Input
                    id='streetAddress'
                    name='streetAddress'
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.streetAddress || ""}
                    invalid={props.touched.streetAddress && props.errors.streetAddress ? true : null}
                />
                <FormFeedback>{props.errors.streetAddress}</FormFeedback>
            </Col>
        </>
    );
};
export default StreetAddressField;
