import React from "react";
import { Col, Label, Input, FormFeedback, FormText } from "reactstrap";
const FirstNameField = (data) => {
    const { props, label, required } = data;
    return (
        <>
            <Label htmlFor='requestedStores' sm={2}>
                {label ? label : "Requested Stores"}
                {required ? <small className='text-danger'>*</small> : ""}
            </Label>
            <Col sm={10}>
                <Input
                    id='requestedStores'
                    name='requestedStores'
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.requestedStores || ""}
                    invalid={props.touched.requestedStores && props.errors.requestedStores ? true : null}
                />
                <FormText>
                    Please include information that will help us identify what store(s) your account should be associated with. This can be your store or location number, franchise business name, or
                    primary contact.
                </FormText>
                <FormFeedback>{props.errors.requestedStores}</FormFeedback>
            </Col>
        </>
    );
};
export default FirstNameField;
