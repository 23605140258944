import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { Col, Label, CustomInput, UncontrolledTooltip, FormFeedback, FormText, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionSquare, faAlarmClock } from "@fortawesome/pro-duotone-svg-icons";
import "@fortawesome/fontawesome-pro/css/all.css";

const UserRoleField = (data) => {
    const { props, label, required, showFormHelp, helpMsg } = data;
    const [roles, setRoles] = useState([]);
    const getRoles = async () => {
        axios
            .get(`${process.env.REACT_APP_APIHOST}/api/getroles`, {
                headers: {
                    authorization: `Bearer ${process.env.REACT_APP_API_AUTH_PASSPHRASE}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setRoles(
                        _.compact(
                            _.map(res.data.roles, (role, i) => {
                                if (role.active) {
                                    return role;
                                }
                            })
                        )
                    );
                } else {
                }
            })
            .catch(function (error) {});
    };
    useEffect(() => {
        if (roles.length >= 1) {
        } else {
            getRoles();
        }
    }, [roles]);
    return (
        <>
            <Label htmlFor='userRole' sm={2}>
                {label ? label : "Account Type"}
                {required ? <small className='text-danger'>*</small> : ""}
                <span style={{ cursor: "pointer" }} id='roleHelpIcon'>
                    &nbsp;
                    <FontAwesomeIcon icon={faQuestionSquare} />
                </span>
            </Label>
            <UncontrolledTooltip placement='right' target='roleHelpIcon'>
                This is to help ensure we provide you with relevant content.
            </UncontrolledTooltip>
            <Col sm={10}>
                <div className={props.errors.userRole && props.touched.userRole ? "rounded border border-danger no-gutters p-2" : "rounded no-gutters p-2"}>
                    <div style={{ columns: 2 }}>
                        {_.map(roles, (role, i) => {
                            return (
                                <CustomInput
                                    key={i}
                                    id={`${role.slug}-radio`}
                                    label={
                                        <>
                                            {role.name} <i className='fad fa-question-square' style={{ cursor: "pointer" }} id={`${role.slug}-helpicon`}></i>
                                            {/* <FontAwesomeIcon icon={faQuestionSquare} style={{ cursor: "pointer" }} id={`${role.slug}-helpicon`} /> */}
                                            <UncontrolledTooltip placement='right' target={`${role.slug}-helpicon`}>
                                                {role.description}
                                            </UncontrolledTooltip>
                                        </>
                                    }
                                    type='radio'
                                    name='userRole'
                                    checked={props.values.userRole === role.slug || false}
                                    value={role.slug}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className={props.errors.userRole && props.touched.userRole ? "text-danger" : "d-none"}>
                    <small>{props.errors.userRole}</small>
                </div>
                {showFormHelp ? (
                    <FormText>
                        <Alert color='info' className='p-1 mb-0'>
                            {helpMsg}
                        </Alert>
                    </FormText>
                ) : (
                    ""
                )}
            </Col>
        </>
    );
};
export default UserRoleField;
