import React from "react";
import { Col, Label, Input, FormFeedback } from "reactstrap";
const MobilePhoneField = (data) => {
    const { props } = data;
    return (
        <>
            <Label htmlFor='mobilePhone' sm={2}>
                Mobile Phone
            </Label>
            <Col sm={4}>
                <Input
                    id='mobilePhone'
                    name='mobilePhone'
                    type='text'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.mobilePhone || ""}
                    invalid={props.touched.mobilePhone && props.errors.mobilePhone ? true : null}
                />
                <FormFeedback>{props.errors.mobilePhone}</FormFeedback>
            </Col>
        </>
    );
};
export default MobilePhoneField;
