import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import config from "./config";
import Layout from "./components/Layout";

import Main from "./pages/Main";
import Help from "./pages/Help";
import ActivationHelp from "./pages/ActivationHelp";
import RequestAccess from "./pages/RequestAccess";
import CurrentUser from "./pages/CurrentUser";

const oktaAuth = new OktaAuth(config.oidc);

const App = (props) => {
    return (
        <Router>
            <Security oktaAuth={oktaAuth}>
                <Switch>
                    <Layout>
                        <Route path='/implicit/callback' component={LoginCallback} />
                        <Route path='/' exact component={Main} {...props} />
                        <Route path='/requestaccess' exact component={RequestAccess} {...props} />
                        <Route path='/help' exact component={Help} {...props} />
                        <Route path='/help/activation' exact component={ActivationHelp} {...props} />
                        <SecureRoute path='/me' exact component={CurrentUser} {...props} />
                    </Layout>
                </Switch>
            </Security>
        </Router>
    );
};

export default App;
