import React, { useState } from "react";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler, Collapse, NavbarText, Container, Row } from "reactstrap";
import { NavLink as RRNavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle, faChartNetwork } from "@fortawesome/pro-duotone-svg-icons";

const TopNavigation = (data) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    return (
        <Navbar color='fb' dark fixed='top' className='flex-md-nowrap shadow d-sm-flex' expand='md'>
            <Container>
                <NavbarBrand tag={RRNavLink} to={"/"} title='Matrix Admin'>
                    <FontAwesomeIcon className='align-middle mr-3' icon={faChartNetwork} size='3x' fixedWidth /> The Matrix
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className='ml-4 mr-auto' navbar>
                        <NavItem>
                            <NavLink to={`/requestaccess`} tag={RRNavLink} activeClassName='active'>
                                Request Access
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={`/help`} tag={RRNavLink} activeClassName='active'>
                                Help
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <NavbarText>Welcome</NavbarText>
                </Collapse>
            </Container>
        </Navbar>
    );
};

export default TopNavigation;
