import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

const OktaSigninWidget = () => {
    let history = useHistory();
    const elm = useRef();
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const config = {
        baseUrl: process.env.REACT_APP_OKTA_DOMAIN,
        redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URL,
        authParams: {
            pkce: true,
        },
        logo: "images/FOCUSBubble_square.png",
        helpSupportNumber: "ssoadmin@focusbrands.com",
        language: "en",
        i18n: {
            en: {
                "primaryauth.title": "Focus Brands Matrix",
            },
        },
        helpLinks: {
            help: "/help",
        },
        brandName: "Focus Brands Matrix",
        customButtons: [
            {
                title: "Request Access",
                className: "btnRequestAccess",
                click: function () {
                    // clicking on the button navigates to another page
                    history.push("/requestaccess");
                },
            },
        ],
    };
    const signIn = new OktaSignIn(config);

    signIn.authClient.session.get().then((res) => {
        // Session exists, show logged in state.
        if (res.status === "ACTIVE") {
            console.log("Okta Session exists");
            if (res.login.search(/@focusbrands.com/gi) !== -1) {
                setModal(true);
            } else {
                window.location.href = process.env.REACT_APP_OKTA_DOMAIN;
            }
        }
        // No session, or error retrieving the session. Render the Sign-In Widget.
        else if (res.status === "INACTIVE") {
            console.log("Okta Session does not exists");
        }
    });

    useEffect(() => {
        if (elm) {
            signIn.renderEl(
                { el: "#okta-login-container" },
                (res) => {
                    res.session.setCookieAndRedirect(process.env.REACT_APP_OKTA_DOMAIN);
                },
                (err) => {
                    console.log(err);
                }
            );
        }
        return () => {
            signIn.remove();
        };
    }, [elm]);

    return (
        <>
            <div id='okta-login-container' ref={elm}></div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Focus Brands Account Notice</ModalHeader>
                <ModalBody>
                    <p>
                        You are attempting to login with a @focusbrands.com account. This login page is specifically for @focusbrandsmatrix users. If you would like to stay on this site and view other
                        pages, please click "Stay Here" below.
                    </p>
                    <p>If you are trying to login, please click on the "Okta" button below.</p>
                </ModalBody>
                <ModalFooter />
                <Row className='mb-3'>
                    <Col sm={6}>
                        <div className='text-center'>
                            <Button color='success' onClick={() => history.push("/help")}>
                                Stay Here
                            </Button>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='text-center'>
                            <Button color='primary' onClick={() => (window.location.href = process.env.REACT_APP_OKTA_DOMAIN)}>
                                Okta
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default OktaSigninWidget;
