import React, { useEffect } from "react";
import _ from "lodash";
import { Col, Label, Input, FormFeedback, FormText, Alert, CustomInput } from "reactstrap";
const EmailField = (data) => {
    const { props, label, required, showFormHelp, helpMsg, checkExistingContactEmails, existingAcctsMsg } = data;
    const debounce = (func, timeout = 500) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    };
    const checkContactEmail = () => {
        if (!props.errors.email && props.values.email) {
            let _fbemailchk = _.includes(props.values.email, "@focusbrands.");
            if (!_fbemailchk) {
                debounce(checkExistingContactEmails(props.values.email));
            }
        }
    };

    return (
        <>
            <Label htmlFor='email' sm={2}>
                {label ? label : "Email"}
                {required ? <small className='text-danger'>*</small> : ""}
            </Label>
            <Col sm={10}>
                <Input
                    id='email'
                    name='email'
                    type='text'
                    onKeyUp={() => checkContactEmail()}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email || ""}
                    invalid={props.touched.email && props.errors.email ? true : null}
                />
                <FormFeedback>{props.errors.email}</FormFeedback>
                {showFormHelp ? (
                    <FormText>
                        <Alert color='info' className='p-1 mb-0'>
                            {helpMsg}
                        </Alert>
                    </FormText>
                ) : (
                    ""
                )}
                {existingAcctsMsg ? (
                    <>
                        <FormText>
                            <Alert color='warning' className='p-1 mb-0'>
                                {existingAcctsMsg}
                            </Alert>
                        </FormText>
                        <Label htmlFor='dupEmail-checkbox'>Duplicate Email</Label>
                        <CustomInput
                            id={`dupEmail-checkbox`}
                            label={<span>I confirm that I need another account with this same email address.</span>}
                            type='checkbox'
                            name={`checkedemail`}
                            checked={props.values.checkedemail}
                            value={props.values.checkedemail}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                        {!props.values.checkedemail ? <div className='text-danger'>You must confirm this.</div> : ""}
                    </>
                ) : (
                    ""
                )}
            </Col>
        </>
    );
};
export default EmailField;
