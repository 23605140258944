import React from "react";
import { Col, Label, Input, FormFeedback } from "reactstrap";
import _ from "lodash";
import Countries from "i18n-iso-countries";

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const CountryCodeField = (data) => {
    const { props, required } = data;
    // console.log(Countries.getNames("en"));
    _.map(Countries.getNames("en"), (name, code) => {
        return <option value={code}>{name}</option>;
    });
    // const countries = _.map()
    return (
        <>
            <Label htmlFor='countryCode' sm={2}>
                Country
                {required ? <small className='text-danger'>*</small> : ""}
            </Label>
            <Col sm={4}>
                <Input
                    id='countryCode'
                    name='countryCode'
                    type='select'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.countryCode || ""}
                    invalid={props.touched.countryCode && props.errors.countryCode ? true : null}>
                    <option value={""}>Select Country</option>
                    {_.map(Countries.getNames("en"), (name, code) => {
                        return (
                            <option key={code} value={code}>
                                {name}
                            </option>
                        );
                    })}
                </Input>
                <FormFeedback>{props.errors.countryCode}</FormFeedback>
            </Col>
        </>
    );
};
export default CountryCodeField;
