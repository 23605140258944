import React from "react";
import { Alert, Col, Row } from "reactstrap";
import { NavLink as RRNavLink, Link } from "react-router-dom";
const ActivationHelp = () => {
    return (
        <>
            <div className='bg-light p-3'>
                <Row>
                    <Col sm={12}>
                        <h1>
                            <img src='/images/FOCUSBubble_square80.png' alt='Focus' />
                            Brands Matrix Activation
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <h2>Here's what you should expect from the activation process</h2>
                        <p>
                            This is an overview of what the activation process is for gaining access to the Matrix. The Matrix is powered by <a href='https://www.okta.com/'>Okta</a>, which is a
                            enterprise level identity management platform. It's the thing that allows you to only have to remember one username and password.
                        </p>
                        <h3>The Activation Email</h3>
                        <p>You've either put in a request, or someone at Focus Brands has created you a new account. When this happens you will recieve an activation from the Matrix.</p>
                        <p>
                            <img className='border rounded shadow mx-auto d-block' src='/images/activationprocess/okta_welcome_email.png' alt='Screenshot of the Okta welcome email' />
                        </p>
                        <p>
                            This activation email provides you with a link to finish activating your account.{" "}
                            <strong>
                                <em>Why do you need to activate your account?</em>
                            </strong>
                        </p>
                        <h3>Setting Up Your Account</h3>
                        <p>
                            Once you receive the email above and click on "Activate Okta Account", you'll be taken to the <strong>Welcome to Focus Brands, Inc</strong> activation page. This step
                            allows you to create a unique password for your account, set a password recovery question, and choose a security question.
                        </p>
                        <Alert color='info'>
                            <strong>Note:</strong> If you skip this setup step your account activation will not be complete and you will not be able to log in.
                        </Alert>
                        <p>
                            <img className='border rounded shadow mx-auto d-block' src='/images/activationprocess/okta_activation_page1.png' alt='Screenshot of the Matrix account activation page 1' />
                            <img className='border rounded shadow mx-auto d-block' src='/images/activationprocess/okta_activation_page2.png' alt='Screenshot of the Matrix account activation page 2' />
                        </p>
                        <p>Once you click on the "Create My Account" button, you will be taken to your Matrix Dashboard, where you will be able to view your new Matrix Dashboard.</p>
                        <p>
                            <img width='650px' className='border rounded shadow mx-auto d-block' src='/images/activationprocess/okta_dashboard.png' alt='Screenshot of the Matrix Dashboard' />
                        </p>
                        <Alert color='info'>
                            <strong>Note:</strong> Depending on the apps that have been assigned to you, you may see a notice to install a browser plugin. This plugin is safe to install and will make
                            using certain apps easier.
                        </Alert>
                        <h3>What Next?</h3>
                        <p>
                            After you've finished this activation process, you should make a note of your username
                            <em>
                                (which will always end with <strong>@focusbrandsmatrix.com</strong>)
                            </em>
                            , and your password.
                        </p>
                        <p>
                            Your account will be used in the future for new apps, but your username and password will stay exactly the same. If you need help, please reach out to one of your Brand
                            Admins listed on the{" "}
                            <Link to={`/help`} tag={RRNavLink}>
                                Help
                            </Link>{" "}
                            page.
                        </p>
                        <Alert color='warning'>
                            <strong>Please Be Aware:</strong> We make extensive use of the Microsoft 365 environment. If you or your company also use Microsoft 365, it can cause conflicts between your
                            Matrix account, and your Microsoft 365 account. If you experience this, please make sure to sign out of your Microsoft 365 account, or utilize a different browser.
                        </Alert>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ActivationHelp;
